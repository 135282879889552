.help-widget {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.help-widget .launcher {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.help-widget .launcher:active {
    transform: translateY(1px);
}

.help-widget .launcher svg {
    position: absolute;
    transition: opacity .2s ease 0s,transform .5s ease 0s,background-color .5s ease 0s;
    padding-top: 2px;
}

.help-widget .launcher .icon-smile {
}

.help-widget .launcher.active .icon-smile {
    transform: rotate(30deg) scale(0);
}

.help-widget .launcher .icon-down {
    transform: rotate(-180deg) scale(0);
}

.help-widget .launcher.active .icon-down {
    transform: rotate(0deg) scale(1);
}

.help-widget .messenger {
    max-width: 370px;
    transition: height .2s ease-in-out,max-height .2s ease-in-out,opacity .2s ease 0s,transform .5s ease 0s,background-color .5s ease 0s;
    transform: translateY(20px);
    visibility: collapse;
    height: 0;
}

.help-widget .messenger.active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    height: auto;
}