@use "~bootstrap/scss/bootstrap.scss" as bootstrap;
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap");

$font-family-sans-serif: "Nunito", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$primary: #facc15;
$success: #9bc801;
$danger: #fe3d05;
$info: bootstrap.$gray-500;
$info: #93c5fd;
$info: #fcd34d;
$warning: #fcd34d;

$grid-breakpoints: (
        xxs: 0,
        xs: 330px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

$headings-font-weight: 600;
$font-size-base: 1rem;
//$font-weight-base: 600;
$nav-link-font-size: 1.125rem;
$navbar-nav-link-padding-x: 1rem;
$nav-link-font-weight: 600;
$nav-link-color: bootstrap.$gray-600;
$component-active-bg: #adb5bd;
$form-check-input-checked-bg-color: black;
$form-check-input-checked-color: black;
$form-check-input-indeterminate-color:          red;
$form-check-input-indeterminate-bg-color:       red;
$form-check-input-bg:                     green;
$form-check-input-border:                 1px solid rgba(white, 1) !default;
$text-muted: rgba(bootstrap.$body-color, 0.5);
// $font-weight-bold: 600;

$hr-color: bootstrap.$gray-600;

$body-bg: #f9f9f9;
$input-bg: white;

$input-color: bootstrap.$body-color;
$input-border-color: bootstrap.$gray-400;
$link-color: bootstrap.$body-color;

$placeholder-opacity-max: .3;

@import '~bootstrap/scss/bootstrap.scss';

.btn-vertical-ellipsis {
  padding: 0;
  font-size: 0;
  font-weight: normal;
}

.btn-vertical-ellipsis::after {
  display: none !important;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.stroke-black {
  stroke: black;
}

.fill-black {
  fill: black;
}

.fill-primary-400 {
  fill: #facc15;
}
.stroke-primary-400 {
  stroke: #facc15;
}

.dropdown-checkboxes
{
}

.dropdown-header {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.dropdown-item {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.dropdown-item.active {
  background-color: white !important;
  color: black !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.dropdown-item.active:hover {
  background-color: bootstrap.$gray-200 !important;
  color: bootstrap.$gray-900 !important;
}

.dropdown-item.active::before {
  position: absolute;
  left: .8rem;
  content: '✓';
  font-weight: 600;
  font-size: 12px;
  display: flex;
  margin-top: 3px;
}

.switch {
  padding: 0;
  align-items: flex-start;
  flex-shrink: 0;
  height: 16px;
  width: 30px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  transition-property: all;
  -webkit-user-select: none;
  background-color: rgb(38, 38, 39);
  opacity: 1;
  appearance: none;
  border-top-width: initial;
  border-right-width: initial;
  border-bottom-width: initial;
  border-left-width: initial;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  outline-style: none;
  margin-top: 0em;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  font-family: system-ui;
  font-variant-caps: normal;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  &:after {
    content: "";
    width: 12px;
    height: 12px;
    position: relative;
    left: 2px;
    background-color: rgb(255, 255, 255);
    display: block;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: all;
    opacity: 1;
    box-sizing: inherit;
  }
  &.active:after {
    transform: translateX(14px);
  }
}