.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  height: 100%;
  min-height: 100%;
}

body {
  min-height: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}

.App {
  min-height: 100%;
  height: 100%;
}

.last-child-mb-0 :last-child {
  margin-bottom: 0 !important;
}

.fa-secondary {
  opacity: 0.4;

}

.fw-icon {
  width: 1.25em;
  text-align: center;
}

.domain-item-enter-active .card {
  transition: background-color 200ms ease-in;
  background-color: #fefce8;
}

.domain-item-enter-done .card {
  transition: background-color 1500ms ease-out;
  background-color: white;
}

.domain-log-item .chevron {
  transition: all 0.3s ease;
}

.domain-log-item.open .chevron {
  transform: rotate(180deg);
}

.domain-log-item-details {
  display: none;
}

.domain-log-item-details.open {
  display: block;
}
